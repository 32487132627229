<template>
  <div class="bg-white" v-if="loaded">
    <!-- Background color split screen for large screens -->
    <div class="hidden lg:block fixed top-0 left-0 w-7/12 h-full bg-white" aria-hidden="true" />
    <div class="hidden lg:block fixed top-0 right-0 w-5/12 h-full bg-gray-100 md:border-l" aria-hidden="true" />

    <header class="relative max-w-8xl mx-auto bg-gray-100 py-6 lg:bg-transparent lg:grid lg:grid-cols-2 lg:gap-x-16 lg:px-8 lg:pt-16 lg:pb-6">
      <div class="max-w-2xl mx-auto px-4 lg:max-w-lg lg:w-full lg:px-0">
        <a href="https://sanifad.com/">
          <span class="sr-only">Workflow</span>
          <img src="https://cdn.shopify.com/s/files/1/0623/6222/2814/files/logo_x1-01_360x.png?v=1646346306" alt="" class="h-8 w-auto lg:hidden" />
          <img src="https://cdn.shopify.com/s/files/1/0623/6222/2814/files/logo_x1-01_360x.png?v=1646346306" alt="" class="hidden lg:block h-8 w-auto" />
        </a>
        
        <nav v-if="this.$router.currentRoute.value.path.indexOf('/thank-you') === -1" aria-label="Progress" class="hidden sm:block text-xs mt-4">
          <ol role="list" class="flex space-x-2">
            <li class="flex items-center">              
              <a v-if="this.$router.currentRoute.value.path === '/step1'" aria-current="page" class="text-gray-900">Riepilogo Informazioni</a>
              <a v-else class="text-gray-500">Riepilogo Informazioni</a>              
            </li>
            <ChevronRightIcon  class="w-5 h-5 text-gray-300 ml-2" aria-hidden="true" />
            <li class="flex items-center">                            
              <a v-if="this.$router.currentRoute.value.path === '/step2'" aria-current="page" class="text-gray-900">Conferma Corsi</a>
              <a v-else class="text-gray-500">Conferma Corsi</a>
            </li>            
          </ol>
        </nav>
        
      </div>      
    </header>

    <main class="relative grid grid-cols-1 max-w-7xl mx-auto lg:px-6 lg:grid-cols-12">
      <h1 class="sr-only">Checkout</h1>

      <section aria-labelledby="summary-heading" class="bg-gray-100 text-gray-600 pt-6 md:pb-12 pb-6  md:px-10 lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-9 lg:col-end-13">
        <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">

          <div class="flex items-center space-x-4 mb-6">
            <img v-if="user.card == 'Card per professionisti sanitari da 50 ECM' " class="w-20" src="../assets/50_ECM.png" />
            <img v-if="user.card == 'Card per professionisti sanitari da 150 ECM' " class="w-20" src="../assets/150_ECM.png" />
            <div class="flex-auto space-y-1">
                <h3 class="text-gray-600">{{ user.card }}</h3>                
            </div>
          </div>

          <h2 id="summary-heading" class="sr-only">Riepilogo</h2>

          <dl>
            <dt class="text-sm font-medium" :class="{ 'mb-6' : courses.selezionati.length <= 0 }">Riepilogo</dt>            
          </dl>

          <div v-if="courses.selezionati.length <= 0" class="border-t mb-6 border-gray-600 border-opacity-10 pt-6">
              <div class="grid grid-cols-6 gap-2">
                <div class="w-100 col-start-1">
                  <img class="w-10" src="../assets/empty.png" />
                </div>
                <div class="col-span-5 col-end-7">
                  <h4 class="text-md text-gray-900 mb-2">I corsi scelti appariranno qui!</h4>
                  <p class="text-xs text-gray-500 font-light">Noi hai aggiunto nessun corso! Una volta aggiunti verranno mostrati qui.</p>
                  <p class="md:invisible text-xs text-gray-500 font-light">Scorri la pagina per aggiungerli.</p>
                </div>
              </div>
            </div>

          <ul role="list" class="text-sm font-medium divide-y divide-white divide-opacity-10">
            <li v-for="course in courses.selezionati" :key="course.id" class="flex items-center py-6 space-x-4">
              <img :src="'https://sanistrapi.caprover.shopifive.com' + course.thumbnail[0].url" :alt="course.thumbnail[0].name" class="flex-none w-30 h-20 rounded-md object-center object-cover" />
              <div class="flex-auto space-y-1">
                <h3 class="text-gray-600">{{ course.Titolo }}</h3>                
              </div>
              <p class="flex-none text-base font-medium text-gray-600">{{ course.ecm }} ECM</p>
            </li>
          </ul>          

          <div class="flex items-center justify-between border-t border-gray-600 border-opacity-10 text-gray-600 pt-6">
              <dt class="text-base">Totale</dt>
              <dd class="text-2xl flex items-end">
                <p class="text-base pr-6">ECM</p>
                {{cartTotal}}
              </dd>
            </div>

          
        </div>
      </section>

      <router-view/>
      
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { computed } from 'vue';
import { state, setUser } from './User';
import { state as courses, setCourses, setSelezionati } from './Course';

const user = computed({
  get: () => state.user 
});

const _courses = [];

export default {    
  setup() {
    return {
      user, 
      setUser,
      _courses,
      courses 
    }
  },
  data: () => {
    return {      
      loaded: false,   
      couponError: false, 
    }
  },  
  methods: {          
    async getOrder(){      
      if(state.user.email){
        return;
      }
      try {        
        
        if(!this.$route.params.id){
          window.open('https://sanifad.com/', "_self");
        }        
        
        let _user = await axios.get(`https://sanistrapi.caprover.shopifive.com/attivazionis/${this.$route.params.id}`);        
        setUser('nome', _user.data.nome);
        setUser('cognome', _user.data.cognome);
        setUser('email', _user.data.email);
        setUser('telefono', _user.data.telefono);
        setUser('indirizzo', _user.data.indirizzo);
        setUser('cap', _user.data.cap);
        setUser('citta', _user.data.citta);
        setUser('provincia', _user.data.provincia);
        setUser('codicefiscale', _user.data.codicefiscale);
        setUser('pec', _user.data.pec);
        setUser('card', _user.data.card);        
        let tipo = "";
        switch (_user.data.card) {
          case "Card per professionisti sanitari da 50 ECM":
            tipo = 50;
            break;
          case "Card per professionisti sanitari da 150 ECM":
            tipo = 150;
            break;
          default:
            break;
        }
        let __courses = await axios.get(`https://sanistrapi.caprover.shopifive.com/corsis?tipo_card=${tipo}`);
        this._courses = __courses.data;
        setCourses(this._courses);        

        if(_user.data.corsis.length > 0){
          console.log(_user.data.corsis);
          setSelezionati(_user.data.corsis);          
          this.$router.push({ name: 'thank-you', params: {id: this.$route.params.id }});
        } else {
          this.$router.push({ name: 'Step1', params: {id: this.$route.params.id }});
        }
        

        this.loaded = true;
      } catch (error) {
        console.log(error);
      }      
    }
  },
  computed: {    
    cartTotal: function() {
      return courses.selezionati.reduce((acc, item) => {
        return acc + parseFloat(item.ecm);
      }, 0);
    }    
  },
  created() {    
    this.$watch(
      () => this.$route.params,
      () => {
        this.getOrder()
      },    
    )    
  },
  components: {    
    ChevronRightIcon    
  }
}
</script>