import {
    createStore
} from '@harlem/core';

const STATE = {
    corsi: [],
    selezionati: [],
    titolo: "",
    link: "",
    thumbnail: "",
    ecm: ""
};

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore('product', STATE);

export const setCourses = mutation('setCourses', (state, payload) => {    
    state.corsi = payload;
    return state;
});

export const setSelezionati = mutation('setSelezionati', (state, payload) => {    
    state.selezionati = payload;
    return state;
});

// export const setCourseId = mutation('setCourseId', (state, payload) => {    
//     state.id = payload;
//     return state;
// });

// export const setCourseTitle = mutation('setCourseTitle', (state, payload) => {    
//     state.titolo = payload;
//     return state;
// });

// export const setCourseLink = mutation('setCourseLink', (state, payload) => {    
//     state.link = payload;
//     return state;
// });

// export const setCourseThumbnail = mutation('setCourseThumbnail', (state, payload) => {    
//     state.thumbnail = payload;
//     return state;
// });

// export const setCourseEcm = mutation('setCourseEcm', (state, payload) => {    
//     state.ecm = payload;
//     return state;
// });
