import {
    createStore
} from '@harlem/core';

const STATE = {
    user: {
        nome: "",
        cognome: "",
        email: "",        
        indirizzo: "",
        cap: "",
        citta: "",
        provincia: "",
        telefono: "",
        codicefiscale: "",
        ragionesociale: "",
        piva: "",
        pec: "",
        card: "",
        fattura: "no",
        contratto: "",
        professione: "",
        disciplina: "",
        submitted: "no"
    },
};

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore('user', STATE);

export const updateValue  = mutation('updateValue', (state, payload) => {       
    state.user = payload;            
});

export const setUser = (key, value) => {
    updateValue({ ...state.user, [key]: value });            
}