import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Harlem from '@harlem/core';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};

createApp(App)
    .use(router)
    .use(Harlem)
    .use(Toast, options)
    .mount('#app')
